<template>
  <div>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <v-card flat class="py-12">
      <v-card-text>
        <v-row align="center" justify="end">
        <v-col cols="12" md="12" sm="4">
          <!--v-btn-toggle v-model="toggle_exclusive"-->
            <!--v-badge bordered :value="10" color="error" overlap
              --><v-btn class="secondary" light @click="showCase('All')">
              All Cases
            </v-btn>
            <!--/-v-badge-->
            <v-btn class="success" light @click="showCase('Submitted')">
              Recieved: New Case Submission
            </v-btn>
            <!--v-btn class="warning" light @click="showCase('RecievedFeedback')">
              Recieved: Doctor's Feedback
            </!--v-btn-->
            <v-btn class="primary" light @click="showCase('Approved')">
              Approved: Treatment Proposal
            </v-btn>
            <v-btn class="error" light @click="showCase('Waiting for approve')">
              Waiting for approve
            </v-btn>
            <v-btn color="orange darken-4" dark @click="showCase('Shipped')">
              Shipped Cases
            </v-btn>
          <!--/v-btn-toggle-->
          </v-col>

          <!--v-col cols="12" class="text-center"> Model: {{ toggle_exclusive }} </!--v-col-->
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="12" md="6">
            <v-text-field
              label="Doctor's name"
              v-model="filterName"
              hint="Doctor's name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="casesTable"
              :sort-by.sync="dataSortColumn"
              :sort-desc.sync="sortDesc"
              :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, -1] }"
              v-if="casesTable.length > 0"
            >
              <template v-slot:item.caseNo="{ item }">
                <v-badge color="red" v-if="item.isOneHr">
                  <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
                </v-badge>
                <v-badge color="orange" v-if="item.isOneDay">
                  <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
                </v-badge>
                <v-badge value="" v-if="!item.isOneDay && !item.isOneHr">
                  <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
                </v-badge>
              </template>
              <template v-slot:item.selectPackage="{ item }">
                {{ item.selectPackage }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ item.status }} 
              </template>
              <template v-slot:item.approvedDate="{ item }">
                <span v-if="item.status == 'Approved'">
                 {{ item.approvedDate | dateFormat }}
                </span>
                <span v-else></span>
              </template>
              <template v-slot:item.inspectorCompleted.approvedDate="{ item }">
                <span
                  style="color: red"
                  v-if="isNewApproved(item.inspectorCompleted.approvedDate) === true"
                >
                  {{ item.inspectorCompleted.approvedDate | dateFormat }}
                </span>
                <span v-else>
                  {{ item.inspectorCompleted.approvedDate | dateFormat }}
                </span>
              </template>
              <template v-slot:item.shippingInformation="{ item }">
                <span
                  v-if="
                    item.status == 'Approved' &&
                    item.shippingInformation.shippingName == '-'
                  "
                >
                  Under fabrication
                </span>
                <span
                  v-if="
                    item.status == 'Approved' &&
                    item.shippingInformation.shippingName != '-'
                  "
                >
                  Shipped
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="cyan lighten-1"
                  v-if="item.status != 'Draft'"
                  @click="uploadPDF(item.caseNo)"
                >
                  Upload PDF/Video
                </v-btn>
                <!--v-btn
                  color="cyan lighten-2"
                  v-if="item.status != 'Draft'"
                  @click="labUpload(item.caseNo)"
                >
                  Upload monitoring file
                </!--v-btn-->

                <v-btn
                  color="cyan lighten-3"
                  v-if="
                    item.status != 'Draft' && (item.sequence == 0 || item.sequence == 3)
                  "
                  @click="selectPackage(item.caseNo, item.sequence)"
                >
                  Select Package
                </v-btn>
                <v-btn
                  color="cyan lighten-5"
                  
                  @click="updateVideoLink(item.caseNo)"
                >
                  Update Video Link
                </v-btn>
                <v-btn
                  color="cyan lighten-5"
                  v-if="item.status == 'Approved'"
                  @click="updateShippingInformation(item.caseNo)"
                >
                  Shipping Information
                </v-btn>
                <!--v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id)"
                  @closeFeedback="closeFeedback"
                  v-if="item.status != 'Draft'"
                >
                  open Feedback
                </v-btn-->
                <v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item, 'lab')"
                  @closeFeedback="closeFeedback"
                >
                  <!--v-badge color="orange"> open Feedback </v-badge-->
                  <v-badge
                    color="orange"
                    v-if="item.status != 'Draft' && isnewFeedback(item)"
                  >
                    open Feedback
                  </v-badge>
                  <v-badge v-else value=""> open Feedback </v-badge>
                </v-btn>

                <!--v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item)"
                  @closeFeedback="closeFeedback"
                  v-else
                >
                  open Feedback
                </v-btn-->

                <v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item, 'inspector')"
                  @closeFeedback="closeFeedback"
                  v-if="item.inspector != '' && item.inspector !== undefined"
                >
                  <v-badge color="orange" v-if="isnewInspectorFeedback(item)">
                    Inspector's Feedback
                  </v-badge>
                  <v-badge v-else value=""> Inspector's Feedback </v-badge>
                </v-btn>
              </template>
              <template v-slot:item.inspector="{ item }">
                <v-select
                  :return-object="returnObject"
                  :items="inspectors"
                  :menu-props="{ top: true, offsetY: true }"
                  label="Select Inspector"
                  v-model="item.inspector"
                  @change="changeInspector(item)"
                ></v-select>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--feedback-app
      v-model="feedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="feedbackDialog"
      @closeFeedback="closeFeedback"
      useBy="LAB"
      v-if="feedbackCaseId"
    ></feedback-app-->
    <feedback-app
      v-model="feedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="feedbackDialog"
      useBy="LAB"
      @closeFeedback="closeFeedback"
      v-if="feedbackCaseId"
    ></feedback-app>
    <inspector-feedback
      v-model="inspectorFeedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="inspectorFeedbackDialog"
      @closeFeedback="closeFeedback"
      useBy="LAB"
      v-if="feedbackCaseId"
    ></inspector-feedback>
    <v-dialog
      v-model="dialogChangeInspector"
      @close="cancelSelectInspector"
      max-width="600px"
      app
    >
      <v-card>
        <v-card-title class="headline"
          >Select {{ inspector }} for case no. {{ caseNo }} ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cancelSelectInspector">Cancel</v-btn>
          <v-btn color="success" @click="changeInspectorConfirm">Confirm</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import SystemBar from "../../library/system-bar/system-bar";
import LabMenu from "../menubar/menubar";
import feedBack from "../../library/feedback/feedback";
import inspectorFeedback from "../../library/inspector-feedback/feedback";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { DateTimeFunc } from "../../../libs/constant.js";
export default {
  components: {
    "bfc-bar": SystemBar,
    "lab-menu": LabMenu,
    "feedback-app": feedBack,
    "inspector-feedback": inspectorFeedback,
  },
  filters: {
    dateFormat: function (val) {
      if (val !== undefined && val != "" && val !== null) {
        let ymd = val.substring(0, 10).split("-");

        let month = DateTimeFunc.TH_MONTH(ymd[1]);
        //if(sixHr){
        //  return '<span color="red">'+ymd[2] + " " + month + " " + (parseInt(ymd[0]) + 543)+' '+val.substring(11, 19)+'</span>';
        //}else{
        return (
          ymd[2] +
          " " +
          month +
          " " +
          (parseInt(ymd[0]) + 543) +
          " " +
          (parseInt(val.substring(11, 13)) + 7) +
          ":" +
          val.substring(14, 19)
        );
        //}
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      caseNo: null,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      dataSortColumn: "inspectorCompleted.approvedDate",
      sortDesc: true,
      feedbackCaseNo: "",
      feedbackDialog: false,
      inspectorFeedbackDialog: false,
      dialogChangeInspector: false,
      inspector: "",
      selectedInspector: {},
      returnObject: true,
      feedbackCaseId: "",
      //defaultPrice: 4000,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      shippingInformation: {
        shippingName: "-",
        trackingNo: "-",
        shippingDate: "-",
      },
      filterStatus: "",
      countInterval: null,
      toggle_exclusive: 0,
      filterName: "",
      cases: [],
      casesTable: [],
      dialog: [],
      headers: [
        {
          text: "Updated",
          value: "updatedAt",
        },
        {
          text: "Case No.",
          value: "caseNo",
        },
        {
          text: "Doctor",
          value: "doctor_name",
        },
        {
          text: "Patient",
          value: "patient_name",
        },
        {
          text: "Package",
          value: "selectPackage",
        },
        {
          text: "Shipping Information",
          value: "shippingInformation",
        },
        {
          text: "Inspector",
          value: "inspector",
        },
        {
          text: "Inspector Approved Date",
          value: "inspectorCompleted.approvedDate",
        },
        {
          text: "Case Status",
          value: "status",
        },
        {
          text: "Approved Date",
          value: "approvedDate",
        },
       
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      newCase: "BFC/newCaseList",
      allCases: "BFC/allCases",
      listSubmitted: "BFC/listSubmitted",
      listApproved: "BFC/listApproved",
      listByStatus: "BFC/listByStatus",
      listInspectorUser: "BFC/getInspectorList",
    }),
    listNewCase() {
      return this.newCase;
    },
    inspectors() {
      let lists = this.listInspectorUser.map((item) => {
        return {
          text: item.memberId.firstname + " " + item.memberId.lastname,
          value: item._id,
        };
      });
      lists.push({
        text: "All",
        value: "",
      });
      return lists;
    },
  },
  beforeDestroy() {
    clearInterval(this.countInterval);
  },
  mounted() {
    //setInterval(this.init(), 15 * 60 * 1000);
    this.init();
    this._interval();
    this.inspectorList();
  },
  methods: {
    ...mapActions({
      setLabCasesList: "BFC/setLabCasesList",
      setfilterCaseStatus: "BFC/setfilterCaseStatus",
      setInspectorList: "BFC/setListInspector",
    }),
    isNewApproved(val) {
      ////console.log(val)
      let isNew = false;
      if (val !== undefined) {
        /********************/

        //let oneDay = false;

        //let updatedAt = new Date(item.updatedAt);
        let currentTime = new Date();
        let diffTime = new Date(
          moment(val, "YYYY-MM-DD HH:mm:ss").add(7, "hours").format("YYYY-MM-DD HH:mm:ss")
        );

        if (
          parseInt((currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)) <= 24
        ) {
          isNew = true;
        }
        /*
                  if (
                    6 <
                      parseInt(
                        (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                      ) &&
                    parseInt(
                      (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                    ) <= 24
                  ) {
                    oneDay = true;
                  }
                  */
        /********************/
      }
      ////console.log(isNew);
      return isNew;
    },
    cancelSelectInspector() {
      this.dialogChangeInspector = false;
      this.casesTable[this.caseIndex].inspector = this.casesTable[
        this.caseIndex
      ].current_inspector;
    },
    changeInspector(item) {
      this.caseNo = item.caseNo;
      this.inspector = item.inspector.text;
      this.dialogChangeInspector = true;
      //console.log(item);
      this.caseIndex = this.casesTable.indexOf(item);
      ////console.log(this.casesTable.indexOf(item));
      this.selectedInspector = item;
      ////console.log(this.selectedInspector);
    },
    async changeInspectorConfirm() {
      this.dialogChangeInspector = false;
      let payload = {
        custId: this.custId,
        _id: this.selectedInspector.id,
        inspector: this.selectedInspector.inspector.value,
      };

      try {
        axios
          .post(
            this.url + "/api/bfc/v1/inspector/assign",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // this.postcodeList = response.data;
            console.log(response);
          });
      } catch (err) {
        this.responseStatus = {
          status: "Error",
          code: 500,
          message: err,
        };
      }
    },
    /* async inspectorList(){
      let payload = {
        custId: this.custId,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/list",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // //console.log(response);
            //console.log(response.data);
            if (response.data.statusCode == 0) {
              //console.log(response.data)
              this.setInspectorList(response.data.data);
              //console.log(this.listInspectorUser);
              this.inspector = this.listInspectorUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
            } else {
              // show error
              //console.log("error response");
            }
          });
      } catch (e) {
        //
        //console.log(e);
      }
      },
    */
    async inspectorList() {
      let payload = {
        custId: this.custId,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/list",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // //console.log(response);
            //console.log(response.data);
            if (response.data.statusCode == 0) {
              //console.log(response.data);
              this.setInspectorList(response.data.data);
              //console.log(this.listInspectorUser);
              this.inspector = this.listInspectorUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
            } else {
              // show error
              //console.log("error response");
            }
          });
      } catch (e) {
        //
        //console.log(e);
      }
    },

    isnewFeedback(item) {
      ////console.log(item.feedback.map(item =>{ return item.read }));
      if (item.feedback.length > 0) {
        return (
          item.feedback
            .map((item) => {
              return item.read;
            })
            .indexOf("LAB") == -1
        );
      } else {
        return true;
      }
    },
    isnewInspectorFeedback(item) {
      ////console.log(item.inspectorFeedback.map(item =>{ return item.read }));
      if (item.inspectorFeedback.length > 0) {
        return (
          item.inspectorFeedback
            .map((item) => {
              return item.read;
            })
            .indexOf("LAB") == -1
        );
      } else {
        return true;
      }
    },

    closeFeedback(val) {
      ////console.log("close feedback");
      this.feedbackDialog = val;
      this.inspectorFeedbackDialog = val;
      this.feedbackCaseNo = "";
      this.feedbackCaseId = "";
      ////console.log(this.feedbackDialog);
    },
    openFeedback(caseNo, caseId, item, dialog) {
      //console.log(item);
      //console.log(caseNo + "  " + caseId);
      ////console.log(this.feedbackDialog);

      this.feedbackCaseNo = caseNo;
      this.feedbackCaseId = caseId;
      /*
      if(dialog != 'inspector'){
        this.feedbackDialog = true;
        this.casesTable[this.casesTable.indexOf(item)].feedback = null;
        item.feedback = null;
      }else{
        this.inspectorFeedbackDialog = true;
      }
      */
      if (dialog != "inspector") {
        this.feedbackDialog = true;
        //Object.assign(item.feedback,{read:"ADMIN"});
        ////console.log(item);
        item.feedback.push({ read: "LAB" });
      } else {
        this.inspectorFeedbackDialog = true;
        //Object.assign(item.inspectorFeedback,{read:"ADMIN"});
        item.inspectorFeedback.push({ read: "LAB" });
      }
      //alert(this.feedbackDialog);
    },

    view(caseNo) {
      this.$router.push("/lab/view/" + caseNo);
    },
    _interval() {
      this.countInterval = setInterval(this.init, 120000);
    },
    async init() {
      //console.log("initial data.....");

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/listCases",
            {
              caseStatus: {},
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // //console.log(response);
            // //console.log(response.data);
            if (response.data.statusCode == 0) {
              //this.cases = response.data.data;
              this.cases = response.data.data //this.cases
                .filter((item) => {
                  let status = "Draft";
                  if (typeof item.caseStatus !== "undefined") {
                    status = item.caseStatus.stateStatus;
                  }
                  //console.log(item.shippingInformation);
                  //console.log(this.filterStatus);
                  //console.log(typeof item.shippingInformation);
                  if (this.filterStatus == "All" || this.filterStatus == "") {
                    return status != "Draft" && item.doctorId !== undefined;
                  } else {
                    if (this.filterStatus == "Approved") {
                      ////console.log(typeof item.shippingInformation === "undefined" && status == this.filterStatus);
                      return (
                        typeof item.shippingInformation === "undefined" &&
                        status == this.filterStatus &&
                        item.doctorId !== undefined
                      );
                    } else if (this.filterStatus == "Shipped") {
                      ////console.log(typeof item.shippingInformation !== "undefined" && status == this.filterStatus);
                      return (
                        typeof item.shippingInformation !== "undefined" &&
                        item.doctorId !== undefined
                      );
                    } else {
                      return status == this.filterStatus && item.doctorId !== undefined;
                    }
                  }
                })
                .map((item) => {
                  ////console.log(item.updatedAt.toString());
                  ////console.log(item);
                  let oneHr = false;
                  let oneDay = false;

                  //let updatedAt = new Date(item.updatedAt);
                  let currentTime = new Date();
                  let diffTime = new Date(
                    moment(item.updatedAt, "YYYY-MM-DD HH:mm:ss")
                      .add(7, "hours")
                      .format("YYYY-MM-DD HH:mm:ss")
                  );

                  if (
                    parseInt(
                      (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                    ) <= 6
                  ) {
                    oneHr = true;
                  }
                  if (
                    6 <
                      parseInt(
                        (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                      ) &&
                    parseInt(
                      (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                    ) <= 24
                  ) {
                    oneDay = true;
                  }

                  let _package = {
                    text: "",
                    value: "",
                    price: 0,
                    packageStatus: "None",
                  };
                  let selectPackage = "";
                  if (typeof item.package !== "undefined") {
                    _package = item.package;
                  }

                  if (typeof _package.text !== "undefined") {
                    selectPackage = _package.text;
                  }

                  // if (typeof item.package.text !== "undefined") {
                  //   selectPackage = item.package.text;
                  // }
                  ////console.log(_package);
                  let shippingInformation =
                    item.shippingInformation || this.shippingInformation;

                  let feedback = [];
                  ////console.log(typeof item.feedback);
                  if (item.feedback !== undefined) {
                    feedback = item.feedback;
                  }
                  let inspectorFeedback = [];
                  ////console.log(typeof item.inspectorFeedback);
                  if (item.inspectorFeedback !== undefined) {
                    inspectorFeedback = item.inspectorFeedback;
                  }
                  let inspectorCompleted = {};
                  if (
                    item.inspectorCompleted !== undefined &&
                    Object.keys(item.inspectorCompleted).length > 0
                  ) {
                    inspectorCompleted = item.inspectorCompleted;
                  }
                  console.log(item);
                  return {
                    updatedAt: item.updatedAt.toString().substring(0, 10),
                    rawUpdatedAt: item.updatedAt,
                    id: item._id,
                    caseNo: item.caseNo,
                    sequence: item.sequence,
                    runNo: item.runNo,
                    doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
                    inspector: item.inspector || "",
                    feedback: feedback,
                    inspectorFeedback: inspectorFeedback,
                    inspectorCompleted: inspectorCompleted,
                    patient_name: item.caseDetail.firstname+" "+item.caseDetail.lastname,
                    selectPackage: selectPackage,
                    status: item.caseStatus.stateStatus,
                    approvedDate: item.caseStatus.stateUpdatedAt || "",
                    shippingInformation: shippingInformation,
                    localUpdatedAt: moment(item.updatedAt, "YYYY-MM-DD HH:mm:ss")
                      .add(7, "hours")
                      .format("YYYY-MM-DD HH:mm:ss"),
                    _package: _package,
                    isOneHr: oneHr,
                    isOneDay: oneDay,
                  };
                });
              this.casesTable = this.cases;
              ////console.log(this.casesTable);
              this.setLabCasesList(this.casesTable);
            } else {
              // show error
              //console.log("error response");
            }
          });
        //console.log(this.casesTable);
      } catch (e) {
        //console.log(e);
      }
    },

    prepareData() {
      this.casesTable = this.cases.map((item) => {
        ////console.log(item.updatedAt.toString());
        ////console.log(item);
        let _package = {
          text: "",
          value: "",
          price: 0,
          packageStatus: "None",
        };
        let selectPackage = "";
        if (typeof item.package !== "undefined") {
          _package = item.package;
        }

        if (typeof _package.text !== "undefined") {
          selectPackage = _package.text;
        }

        // if (typeof item.package.text !== "undefined") {
        //   selectPackage = item.package.text;
        // }
        let status = "Draft";
        if (typeof item.caseStatus !== "undefined") {
          status = item.caseStatus.stateStatus;
        }
        let shippingInformation = item.shippingInformation || this.shippingInformation;
        let feedback = [];
        ////console.log(typeof item.feedback);
        if (item.feedback === undefined) {
          feedback = item.feedback;
        }

        let inspectorFeedback = [];
        ////console.log(typeof item.inspectorFeedback);
        if (item.inspectorFeedback !== undefined) {
          inspectorFeedback = item.inspectorFeedback;
        }

        let inspectorCompleted = {};
        if (
          item.inspectorCompleted !== undefined &&
          Object.keys(item.inspectorCompleted).length > 0
        ) {
          inspectorCompleted = item.inspectorCompleted;
        }

        let oneHr = false;
        let oneDay = false;

        return {
          // updatedAt: item.updatedAt.toString().substring(0, 10),
          // id: item._id,
          // caseNo: item.caseNo,
          // sequence: item.sequence,
          // runNo: item.runNo,
          // doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
          // feedback: feedback,
          // selectPackage: selectPackage,
          // patient_name: item.caseDetail.patient_name,
          // shippingInformation: shippingInformation,
          // status: status,
          // _package: _package,
          updatedAt: item.updatedAt.toString().substring(0, 10),
          rawUpdatedAt: item.updatedAt,
          id: item._id,
          caseNo: item.caseNo,
          sequence: item.sequence,
          runNo: item.runNo,
          doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
          inspector: item.inspector || "",
          inspectorCompleted: inspectorCompleted,
          feedback: feedback,
          inspectorFeedback: inspectorFeedback,
          patient_name: item.caseDetail.firstname+" "+item.caseDetail.lastname,
          selectPackage: selectPackage,
          //status: item.caseStatus.stateStatus,
          status: status,
          approvedDate: item.caseStatus.stateUpdatedAt || "",
          shippingInformation: shippingInformation,
          localUpdatedAt: moment(item.updatedAt, "YYYY-MM-DD HH:mm:ss")
            .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
          _package: _package,
          isOneHr: oneHr,
          isOneDay: oneDay,
        };
      });
    },
    uploadPDF(id) {
      this.$router.push("/lab/upload-pdf/" + id);
    },

    updateShippingInformation(id) {
      this.$router.push("/lab/shipping/" + id);
    },

    updateVideoLink(id) {
      this.$router.push("/lab/update-link/" + id);
    },

    labUpload(id) {
      this.$router.push("/lab/lab-upload/" + id);
    },
    selectPackage(id, sequence) {
      this.$router.push("/lab/select-package/" + id + "/" + sequence);
    },

    caseStatusColor(val) {
      let bgColor = "primary";
      ////console.log(typeof val.stateStatus);
      //let stateStatus =
      if (typeof val.stateStatus != "undefined") {
        switch (val.stateStatus) {
          case "Submitted":
            bgColor = "success";
            break;
          case "RecievedFeedback":
            bgColor = "warning";
            break;
          case "Approved":
            bgColor = "primary";
            break;
          default:
            //bgColor = "primary";
            break;
        }
      }
      return bgColor;
    },
    showCase(stateStatus) {
      ////console.log(stateStatus);
      this.filterStatus = stateStatus;
      //console.log("stateStatus");
      //console.log(stateStatus);
      switch (stateStatus) {
        // case "Submitted":
        //   ////console.log("RecievedNewCase");
        //   //this.cases = this.listSubmitted;

        //   //this.setfilterCaseStatus("Submitted");
        //   //this.casesTable = this.listlistByStatus;
        //   this.casesTable = this.cases.filter((item) => {
        //     let status = "Draft";
        //     if (typeof item.caseStatus !== "undefined") {
        //       status = item.caseStatus.stateStatus;
        //     }
        //     return item.status == this.filterStatus;
        //     //return item.case;
        //   });
        //   ////console.log(this.cases);
        //   break;
        // case "RecievedFeedback":
        //   this.setfilterCaseStatus("RecievedFeedback");
        //   break;
        //case "Approved":
        ////console.log('Approved Case');
        //this.casesTable = this.cases.filter((item) => {
        //  return item.shippingInformation.shippingName == "-" && item.status == "Approved" ;
        //});
        //break;
        // case "WaitingForApprove":
        //   this.setfilterCaseStatus("Waiting for approve");
        //   this.cases = this.listByStatus;
        //   break;
        //case "Shipped":
        ////console.log("shipped");

        //this.casesTable = this.cases.filter((item) => {
        //  return item.shippingInformation.shippingName != "-" && item.status == "Approved"  ;
        //});
        //break;
        /*case "All":
          //bgColor = "primary";

          //this.cases = this.allCases;
          clearInterval(this.countInterval);
          this._interval();
          /*
          this.casesTable = this.cases.filter((item) => {
            // let status = "Draft";
            // if (typeof item.status !== "undefined") {
            //   status = item.caseStatus.stateStatus;
            // }
            return item.status != "Draft";
            //return item.case;
          });

          break;
          */
        default:
          //bgColor = "primary";
          clearInterval(this.countInterval);
          this.init();
          this._interval();

          //this.cases = this.allCases;
          //this.casesTable = this.cases.filter((item) => {
          // let status = "Draft";
          // if (typeof item.status !== "undefined") {
          //   status = item.caseStatus.stateStatus;
          // }
          //return item.status == this.filterStatus;
          //return item.case;
          //});
          break;
      }
    },
    viewCase(caseNo) {
      this.$router.push("/lab/case/" + caseNo);
    },
  },
  watch: {
    toggle_exclusive: {
      handler() {
        ////console.log(val);
        //console.log(this.toggle_exclusive);
      },
    },
    filterName: {
      handler(val) {
        ////console.log("filterName " + val);
        this.casesTable = this.cases
          // .map((item) => {
          //   ////console.log(item.updatedAt.toString());
          //   ////console.log(item);
          //   let _package = {
          //     text: "",
          //     value: "",
          //     price: 0,
          //     packageStatus: "None",
          //   };
          //   let selectPackage = "";
          //   if (typeof item.package !== "undefined") {
          //     _package = item.package;
          //   }

          //   if (typeof _package.text !== "undefined") {
          //     selectPackage = _package.text;
          //   }

          //   // if (typeof item.package.text !== "undefined") {
          //   //   selectPackage = item.package.text;
          //   // }
          //   ////console.log(_package);

          //   let status = "Draft";
          //   if (typeof item.caseStatus !== "undefined") {
          //     status = item.caseStatus.stateStatus;
          //   }

          //   let shippingInformation =
          //     item.shippingInformation || this.shippingInformation;

          //   return {
          //     updatedAt: item.updatedAt.toString().substring(0, 10),
          //     id: item._id,
          //     caseNo: item.caseNo,
          //     sequence: item.sequence,
          //     runNo: item.runNo,
          //     doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
          //     feedback: "",
          //     selectPackage: selectPackage,
          //     patient_name: item.caseDetail.patient_name,
          //     shippingInformation: shippingInformation,
          //     status: status,
          //     _package: _package,
          //   };
          // })
          .filter((item) => {
            //console.log(item);
            //console.log(val);
            return (
              item.doctor_name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              item.caseNo.indexOf(val) > -1
            );
          });
      },
    },
    cases: {
      handler(val) {
        ////console.log(val);
        if (typeof val === "undefined") {
          this.cases = [];
        }
        this.prepareData();
      },
    },
  },
};
</script>
<style scope>
/* table {
  width: 100%;
}

table td,
table th {
  border: 1px solid;
} */
th {
  text-align: center !important;
}
</style>
