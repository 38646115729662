var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('bfc-bar'),_c('lab-menu'),_c('v-card',{staticClass:"py-12",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"4"}},[_c('v-btn',{staticClass:"secondary",attrs:{"light":""},on:{"click":function($event){return _vm.showCase('All')}}},[_vm._v(" All Cases ")]),_c('v-btn',{staticClass:"success",attrs:{"light":""},on:{"click":function($event){return _vm.showCase('Submitted')}}},[_vm._v(" Recieved: New Case Submission ")]),_c('v-btn',{staticClass:"primary",attrs:{"light":""},on:{"click":function($event){return _vm.showCase('Approved')}}},[_vm._v(" Approved: Treatment Proposal ")]),_c('v-btn',{staticClass:"error",attrs:{"light":""},on:{"click":function($event){return _vm.showCase('Waiting for approve')}}},[_vm._v(" Waiting for approve ")]),_c('v-btn',{attrs:{"color":"orange darken-4","dark":""},on:{"click":function($event){return _vm.showCase('Shipped')}}},[_vm._v(" Shipped Cases ")])],1)],1)],1)],1),_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Doctor's name","hint":"Doctor's name"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.casesTable.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.casesTable,"sort-by":_vm.dataSortColumn,"sort-desc":_vm.sortDesc,"footer-props":{ 'items-per-page-options': [25, 50, 100, 200, -1] }},on:{"update:sortBy":function($event){_vm.dataSortColumn=$event},"update:sort-by":function($event){_vm.dataSortColumn=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.caseNo",fn:function(ref){
var item = ref.item;
return [(item.isOneHr)?_c('v-badge',{attrs:{"color":"red"}},[_c('a',{on:{"click":function($event){return _vm.view(item.caseNo)}}},[_vm._v(_vm._s(item.caseNo))])]):_vm._e(),(item.isOneDay)?_c('v-badge',{attrs:{"color":"orange"}},[_c('a',{on:{"click":function($event){return _vm.view(item.caseNo)}}},[_vm._v(_vm._s(item.caseNo))])]):_vm._e(),(!item.isOneDay && !item.isOneHr)?_c('v-badge',{attrs:{"value":""}},[_c('a',{on:{"click":function($event){return _vm.view(item.caseNo)}}},[_vm._v(_vm._s(item.caseNo))])]):_vm._e()]}},{key:"item.selectPackage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.selectPackage)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.approvedDate",fn:function(ref){
var item = ref.item;
return [(item.status == 'Approved')?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.approvedDate))+" ")]):_c('span')]}},{key:"item.inspectorCompleted.approvedDate",fn:function(ref){
var item = ref.item;
return [(_vm.isNewApproved(item.inspectorCompleted.approvedDate) === true)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.inspectorCompleted.approvedDate))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.inspectorCompleted.approvedDate))+" ")])]}},{key:"item.shippingInformation",fn:function(ref){
var item = ref.item;
return [(
                  item.status == 'Approved' &&
                  item.shippingInformation.shippingName == '-'
                )?_c('span',[_vm._v(" Under fabrication ")]):_vm._e(),(
                  item.status == 'Approved' &&
                  item.shippingInformation.shippingName != '-'
                )?_c('span',[_vm._v(" Shipped ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(item.status != 'Draft')?_c('v-btn',{attrs:{"color":"cyan lighten-1"},on:{"click":function($event){return _vm.uploadPDF(item.caseNo)}}},[_vm._v(" Upload PDF/Video ")]):_vm._e(),(
                  item.status != 'Draft' && (item.sequence == 0 || item.sequence == 3)
                )?_c('v-btn',{attrs:{"color":"cyan lighten-3"},on:{"click":function($event){return _vm.selectPackage(item.caseNo, item.sequence)}}},[_vm._v(" Select Package ")]):_vm._e(),_c('v-btn',{attrs:{"color":"cyan lighten-5"},on:{"click":function($event){return _vm.updateVideoLink(item.caseNo)}}},[_vm._v(" Update Video Link ")]),(item.status == 'Approved')?_c('v-btn',{attrs:{"color":"cyan lighten-5"},on:{"click":function($event){return _vm.updateShippingInformation(item.caseNo)}}},[_vm._v(" Shipping Information ")]):_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openFeedback(item.caseNo, item.id, item, 'lab')},"closeFeedback":_vm.closeFeedback}},[(item.status != 'Draft' && _vm.isnewFeedback(item))?_c('v-badge',{attrs:{"color":"orange"}},[_vm._v(" open Feedback ")]):_c('v-badge',{attrs:{"value":""}},[_vm._v(" open Feedback ")])],1),(item.inspector != '' && item.inspector !== undefined)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openFeedback(item.caseNo, item.id, item, 'inspector')},"closeFeedback":_vm.closeFeedback}},[(_vm.isnewInspectorFeedback(item))?_c('v-badge',{attrs:{"color":"orange"}},[_vm._v(" Inspector's Feedback ")]):_c('v-badge',{attrs:{"value":""}},[_vm._v(" Inspector's Feedback ")])],1):_vm._e()]}},{key:"item.inspector",fn:function(ref){
                var item = ref.item;
return [_c('v-select',{attrs:{"return-object":_vm.returnObject,"items":_vm.inspectors,"menu-props":{ top: true, offsetY: true },"label":"Select Inspector"},on:{"change":function($event){return _vm.changeInspector(item)}},model:{value:(item.inspector),callback:function ($$v) {_vm.$set(item, "inspector", $$v)},expression:"item.inspector"}})]}}],null,false,452448548)}):_vm._e()],1)],1)],1)],1),(_vm.feedbackCaseId)?_c('feedback-app',{attrs:{"caseNo":_vm.feedbackCaseNo,"caseId":_vm.feedbackCaseId,"custId":_vm.custId,"dialog":_vm.feedbackDialog,"useBy":"LAB"},on:{"closeFeedback":_vm.closeFeedback},model:{value:(_vm.feedbackDialog),callback:function ($$v) {_vm.feedbackDialog=$$v},expression:"feedbackDialog"}}):_vm._e(),(_vm.feedbackCaseId)?_c('inspector-feedback',{attrs:{"caseNo":_vm.feedbackCaseNo,"caseId":_vm.feedbackCaseId,"custId":_vm.custId,"dialog":_vm.inspectorFeedbackDialog,"useBy":"LAB"},on:{"closeFeedback":_vm.closeFeedback},model:{value:(_vm.inspectorFeedbackDialog),callback:function ($$v) {_vm.inspectorFeedbackDialog=$$v},expression:"inspectorFeedbackDialog"}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600px","app":""},on:{"close":_vm.cancelSelectInspector},model:{value:(_vm.dialogChangeInspector),callback:function ($$v) {_vm.dialogChangeInspector=$$v},expression:"dialogChangeInspector"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Select "+_vm._s(_vm.inspector)+" for case no. "+_vm._s(_vm.caseNo)+" ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.cancelSelectInspector}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.changeInspectorConfirm}},[_vm._v("Confirm")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }